<template>
    <div>
        <div class="form-row w-full">
            <div class="form-col">
                <label>Images</label>
                <file-manager
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    main-directory="cms"
                    :max-upload-size="20"
                    :max-nb-of-files="nbImages"
                    :noCropper="true"
                    v-model="featuredImages"
                >
                </file-manager>
                <span v-if="hasError('featuredImages')" class="form-error">{{ showError('featuredImages') }}</span>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter,  FileManager },
        emits: ['save-page-widget'],
        data: () => {
            return {
                featuredImages: [],
                nbImages: 3,
                isProcessing: false,
            }
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    let images=[]
                    for( let i = 0; i < this.featuredImages.length; i++){
                        images[i] = (this.getThumbnailUrlByFileId(this.featuredImages[i]));
                    }
                    this.isProcessing = true;
                    this.$emit('save-page-widget', { images: JSON.stringify(images) });
                }
            }
        },
        validations: {
            'featuredImages' : 'required | length:nbImages',
        },
        created() {
            this.initValidator();

            this.validator.setCustomErrorMessages({
                'featuredImages.length' : 'You must select 3 featured images.',
            })

            if (this.values.length){
                let images=[]
                for( let i = 0; i < JSON.parse(this.getValue('images')).length; i++){
                    images[i] = (this.getFileIdByThumbnailUrl(JSON.parse(this.getValue('images'))[i]));
                }
                this.featuredImages = JSON.stringify(images) ? images : [];
            }
        }
    }
</script>
